export const exceptions = {
  '500|1001': 'Invalid Status Code',
  '404|2008': 'Model Not Found',
  '404|2003': 'Route Not Defined',
  '404|2004': 'Event Not Found',
  '422|2005': 'Unprocessable Entity',
  '400|3015': 'Email Already Exists',
  '400|3017': 'Phone Number Already Exists',
  '403|1001': 'Account Forbidden Due to Inactivity',
  '403|1002': 'Account Forbidden',
  '400|3004': 'Staff Not Found',
  '400|3104': 'User Not Found',
  '400|2001': 'OTP Type Does Not Exist',
  '400|2002': 'Invalid OTP',
  '400|4001': 'Role Already Exists',
  '400|4002': 'Role Not Found',
  '400|4101': 'Permission Already Exists',
  '400|4102': 'Permission Not Found',
  '403|0000': 'Not Modified',
  '401|0000': 'Unauthorized',
  '403|0001': 'Forbidden',
  '500|0000': 'Internal Server Error',
  '400|0000': 'Bad Request',
  '400|3010': 'Email Already In Used',
  '400|3110': 'Email Already In Used',
  '404|5202': 'Course Level Not Found',
  '404|5102': 'Course Category Not Found',
  '404|5103': 'Course Category Name Already In Used',
  '422|5102': 'Course Category Connected To Course',
  '400|5002': 'Course Not Found',
  '400|5003': 'Invalid Course Foundation',
  '400|5004': 'Invalid Course',
  '400|5005': 'Invalid Course Section',
  '400|5006': 'Total Point Not Equal 100',
  '400|5007': 'Course Code Already In Used',
  '400|5009': 'Course Tag Not Found',
  '400|5010': 'Maximum 3 Course Tags',
  '400|5011': 'Locked Course Status',
  '400|5012': 'Course Foundation Invalid Level',
  '400|5013': 'Invalid Connection Between Courses',
  '400|5014': 'Invalid Course Type',
  '400|5020': "Course Section Part's Code Already In Used",
  '400|5022': 'Course Section Type Part Not Found',
  '400|5030': "Course Section Chapter's Code Already In Used",
  '400|5032': 'Course Section Type Chapter Not Found',
  '400|5040': "Course Section Unit's Code Already In Used",
  '400|5042': 'Course Section Type Unit Not Found',
  '400|5050': "Course Section Activity's Code Already In Used",
  '400|5052': 'Course Section Type Activity Not Found',
  '400|5053': 'Invalid Course Activity',
  '400|5102': 'Course Learning Outcome Not Found',
  '400|5103': 'At Least One Course Outcome',
  '422|5103': 'Course Learning Outcome Connected To Course Section',
  '400|5202': 'Course Outcome Not Found',
  '400|4201': 'Resource Already Exists',
  '400|4202': 'Resource Not Found',
  '400|6002': "Quiz's Question Not Found",
  '400|6003': "Invalid Quiz's Course",
  '400|6004': 'Invalid Quiz Type',
  '400|6005': 'Quiz Not Found',
  '400|6006': "Invalid Quiz's Part",
  '400|6007': 'Invalid Quiz',
  '400|6008': 'Locked Quiz Status',
  '403|5902': 'This Question Already In Used',
  '403|6010': "You Don't Have Permission To View This Page, Please Login Again",
  '404|071704': 'Course Section Note Not Found',
  '400|100006': 'Class User Not Allow Extend',
  '400|060710': 'Quiz Attempt Limited',
  '400|100010': 'Maximum Class Capacity Reached',
  '400|100002': 'To Add Students, Course Is Required!',
  '400|100306': 'Cannot Edit Class Because Class Has Student',
  '400|100003': 'Examination Not Found',
  '400|100307': "Student's Level Not Equal Foundation Class's Level",
  '400|100001': 'Class Code Already Exists',
  '400|6202': 'Mark Of Question Must Be Greater Than 0',
  '400|100004': 'Class Not Found',
  '400|100401': 'Trial Class With The Same Course Already Existed',
  '400|100322': 'Students Have Learned The Course In This Class!',
  '404|000000': 'Not Found',
  '304|000000': 'Not Modified',
  '401|000000': 'Unauthorized',
  '403|000000': 'Forbidden',
  '400|000000': 'Bad Request',
  '403|000005': 'Forbidden Inactive',
  '403|000008': 'Your account has been deactivated',
  '403|000010': 'The Number Of Logins Has Been Exceeded',
  '404|000304': 'Event Not Found',
  '422|000405': 'Unprocess Entity',
  '400|010004': 'Student Not Found',
  '400|010401': 'Username Already Exists',
  '400|010501': 'Email Already Exists',
  '400|010601': 'Phone Already Exists',
  '400|010704': 'Level Of Student Not Found',
  '400|020004': 'Staff Not Found',
  '400|020501': 'Email Already Exists',
  '400|030004': 'Question Not Found',
  '400|030022': 'Question Already In Used',
  '400|030108': 'Question Can Not Be Updated',
  '400|030208': 'Question Can Not Be Deleted',
  '400|050004': 'Case Study Story Not Found',
  '400|050005': 'Topic Added And Removed At The Same Time',
  '400|060004': 'Quiz Not Found',
  '400|060005': 'Invalid Quiz',
  '400|060022': 'Quiz Already In Used',
  '400|060104': 'Quiz Questions Not Found',
  '400|060205': 'Invalid Course For Quiz',
  '400|060305': 'Invalid Part For Quiz',
  '400|060405': 'Invalid Quiz Status',
  '400|060409': 'Locked Quiz Status',
  '400|060505': 'Invalid Quiz Type',
  '400|060604': 'Quiz Section Category Not Found',
  '400|060711': 'Invalid Quiz Setting Minimum Grading',
  '400|060904': 'Quiz Attempt Not Found',
  '403|060908': 'Quiz Attempt Block Access',
  '400|060910': 'Maximum Quiz Attempt Reached',
  '400|061004': 'Quiz Attempt Answer Not Found',
  '400|061101': 'Question Already Existed In Quiz Section',
  '400|070004': 'Course Not Found',
  '400|070005': 'Invalid Course',
  '400|070022': 'Course Already In Used',
  '400|070205': 'Invalid Foundation Course',
  '400|070208': 'Non Foundation Course With Level',
  '400|070218': 'Foundation Class Can Not Be Modified',
  '400|070305': 'Invalid Course Type',
  '400|070403': 'Course Code Already Existed',
  '400|070505': 'Invalid Total Points',
  '400|070703': 'Course Part Code Already Existed',
  '400|070704': 'Course Part Not Found',
  '400|070803': 'Course Chapter Code Already Existed',
  '400|070804': 'Course Chapter Not Found',
  '400|070903': 'Course Unit Code Already Existed',
  '400|070904': 'Course Chapter Not Found',
  '400|071003': 'Course Activity Code Already Existed',
  '400|071004': 'Course Activity Not Found',
  '400|071005': 'Invalid Course Activity',
  '400|071204': 'Course Tab Document Not Found',
  '400|071207': 'Course Tab Document Type Can Not Be Changed',
  '400|071304': 'Course Learning Outcome Not Found',
  '422|071324': 'Course Learning Outcome Connected To Course Section',
  '400|071404': 'Course Outcome Not Found',
  '400|071412': 'Course Outcome Not Empty',
  '400|071424': 'Course Outcome Connected With Activity',
  '400|071504': 'Course Tag Not Found',
  '400|071509': 'Locked Course Status',
  '400|071510': 'Maximum 3 Course Tags',
  '400|071604': 'Course Level Not Found',
  '400|071605': 'Invalid Course Level',
  '404|071804': 'Course Discussion Or Parent Discussion Not Found',
  '400|071905': 'Invalid Course Section',
  '400|072001': 'Foundation Course Connected With Normal Course',
  '400|072005': 'Invalid Connection Between Courses',
  '400|080004': 'Course Category Not Found',
  '400|080104': 'Invalid Course Category For Examination',
  '400|080201': 'Course Category Name Already Existed',
  '422|080324': 'Course Category Connected To Course',
  '400|090001': 'Role Already Exist',
  '400|090004': 'Role Not Found',
  '400|090101': 'Permission Already Exits',
  '400|090104': 'Permission Not Found',
  '400|100016': 'Class Is Not Activated',
  '400|100021': 'Class Has Ended',
  '400|100104': 'Create Class Examination Id Not Found',
  '400|100204': 'Class Area Not Found',
  '400|100304': 'Class Teacher Not Found',
  '400|100319': 'Student Not Allowed To Active Course',
  '400|100320': 'Student Not Allowed To Extend Course',
  '400|100327': 'Student Not In Class',
  '400|100329': 'Class Has Student',
  '400|100330': 'Student Is Studying This Course In Another Class',
  '400|100417': 'Trial Class With Fixed Duration',
  '400|100505': 'Invalid Flexible Day For Flexible Class',
  '400|100704': 'Revision Class Not Found',
  '400|100710': 'Maximum Revision Class Capacity Reached',
  '400|100807': 'Course And Class Type Not Match',
  '400|100910': 'Maximum Class Capacity Reached',
  '400|101005': 'Invalid Class Schedule',
  '400|101105': 'Invalid Class Status',
  '400|101207': 'Student And Foundation Level Not Match',
  '400|101304': 'Class Mentor Not Found',
  '400|101428': 'Finished At Large Than Started At',
  '400|101701': 'Class Code Already Existed',
  '400|101815': 'Student Not Pass Foundation',
  '400|101904': 'Create Class Course Id Not Found',
  '404|110004': 'Certificate Not Found',
  '403|110008': 'Certificate Can Not Be Used When Blocked',
  '403|110114': 'Certificate Status Can Not Be Changed',
  '400|120004': 'Notification Already Exist',
  '400|120018': 'Notification Can Not Be Modified',
  '400|120105': 'Invalid Notification Timer',
  '400|120205': 'Invalid Notification Status',
  '400|140104': 'Cloudflare Token Already Exist',
  '400|150001': 'Resource Already Exist',
  '400|150004': 'Resource Not Found',
  '400|150025': 'Resource Can Not Be Deleted',
  '400|150202': 'Token Download Not Exist',
  '400|150302': 'File Key Not Exist',
  '400|160004': 'Group Already Exist',
  '400|170104': 'Classification Already Exist',
  '400|170226': 'Deal Not Associated To Contact',
  '400|180004': 'Question Tag Not Found',
  '400|190004': 'Question Topic Not Found',
  '400|190127': 'Question Topic With No Question',
  '400|200004': 'Examination Not Found',
  '404|200104': 'Examination Type Not Found',
  '400|200201': 'Revision Class For This Exam Has Already Existed',
  '400|210002': 'Otp Code Not Found',
  '400|210005': 'Invalid Otp Code',
}
